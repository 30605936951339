import {LatLng} from "../../NFTRequests/types";
import {useEffect, useRef} from "react";
import {useMap} from "../../NFTRequests/modal/MapModal/useMap";

type Coordinates = number[];

export default function ShapedMap(props: {markers: LatLng[]}) {
    const mapRef = useRef<HTMLDivElement>(null);
    const {map, setCenter, drawRegion} = useMap();

    let updateMap = () => {
        if (mapRef.current) {
            map.setTarget(mapRef.current);
            map.updateSize();

            let coordinates = props.markers.map((m: any) => {
                return [m.longitude, m.latitude];
            });

            if (props.markers.length > 0) {
                setCenter(coordinates[0]);
                drawRegion(coordinates);
            }
        }
    };

    useEffect(() => {
        updateMap();
    }, [props.markers]);

    return <div
        className="map-container"
        style={{width: "100%", height: "50vh", paddingTop: 10}}
    >
        <div
            id="map"
            ref={mapRef}
            style={{width: "100%", height: "100%"}}
        ></div>
    </div>
}

export enum NftRequestStatus {
  PENDING = "pending",
  REVIEW = "review",
  REJECTED = "rejected",
  APPROVED = "approved",
}

export interface LatLng {
  latitude: number;
  longitude: number;
}

export function getMiddle(prop: "latitude" | "longitude", markers: LatLng[]) {
  let values = markers.map(m => m[prop]);
  let min = Math.min(...values);
  let max = Math.max(...values);
  if (prop === 'longitude' && (max - min > 180)) {
    values = values.map(val => val < max - 180 ? val + 360 : val);
    min = Math.min(...values);
    max = Math.max(...values);
  }
  let result = (min + max) / 2;
  if (prop === 'latitude' && result > 180) {
    result -= 360
  }
  return result;
}

export function findCenter(markers: LatLng[]) {
  return {
    lat: getMiddle('latitude', markers),
    lng: getMiddle('longitude', markers)
  }
}

interface BaseNftRequestProps {
  activity: string;
  propertyName: string;
  previous_crop?: string;
  markers: LatLng[];
  area: number;
  seedDate: string;
  expireDate: string;
  harvestDate: string;
  user: string;
  crop: string;
}

export interface CID {
  cid?: string;
  name?: string;
  createdAt?: string;
  uploader?: string;
  role?: string;
}

export interface RequestFile {
  cid?: string;
  name?: string;
  url?: string;
  createdAt?: string;
  uploader?: string;
  role?: string;
}

export interface ActivityInfo {
  activity: string;
  title: string;
  duration: number;
  commission: number;
  active: boolean;
}

export interface PermanencyFactor {
  _id: string;
  title: string;
}

export interface NftRequestProps extends BaseNftRequestProps {
  _id: string;
  auditor: string;
  status: NftRequestStatus;
  createdAt: string;
  updatedAt: string;
  tokensCalculated: number;
  tokensGiven: number;
  img?: string;
  cidCertificate?: string;
  formatted_location?: string;
  cids?: CID[];
  files?: RequestFile[];
  pictures?: RequestFile[];
  documents?: RequestFile[];
  calculatedArea: string;
  density: string;
  seedFactor: string;
  nft: string;
  more_information?: string;
  other_documentation?: string;
  reject_reason?: string;
  profile?: {
    name: string;
    surname: string;
  };
  commission: number;
  duration: number;
  gndvi: number;
  stubbleRate?: number;
  absorption_rate: number;
  performsCropRotation?: boolean;
  performsChemicalInputEfficiency?: boolean;
  performsWaterManagementEfficiency?: boolean;
  performsDirectSeeding?: boolean;
  permanencyFactor: PermanencyFactor | undefined;
  permanency_factor_value: number | undefined;
  radicular_permanency_factor?: number | undefined;
  version?: string
  activity_info: ActivityInfo | undefined;
}

export interface RequestLogs {
  _id: string;
  request_id: string;
  data: NftRequestProps;
  address: string;
  role: string;
  changes: string[];
  createdAt: string;
  updatedAt: string;
}

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import Button from "@mui/material/Button";
import { useEffect, useRef, useState } from "react";
import { useMap } from "./useMap";
import "ol/ol.css";
import { LatLng, NftRequestProps } from "../../types";
import { Shapefile, ShapeType, ShapePolygon } from "shapefile.js";
import useNftRequests from "../../useNftRequests";
import styled from "styled-components";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Grid from "@mui/material/Grid";

export type MapModalProps = {
  open: boolean;
  request: NftRequestProps;
  onClose: () => void;
  onUpdate: (coordinates: LatLng[]) => Promise<boolean>;
};

type Coordinates = number[];
type CoordinatesLogs = {
  coordinates: Coordinates[];
  title: string;
  id: string;
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export const MapModal = (props: MapModalProps) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const {map, setCenter, drawRegion} = useMap();
  const [shapefile, setShapefile] = useState<Shapefile>();

  const {getMapLogs} = useNftRequests();
  const [selectedLog, setSelectedLog] = useState<CoordinatesLogs>();

  const [logs, setLogs] = useState<CoordinatesLogs[]>([]);
  const [coordinates, setCoordinates] = useState<Coordinates[]>([]);
  const [updatedCoordinates, setUpdatedCoordinates] = useState<Coordinates[]>(
    [],
  );

  const handleUpdate = () => {
    let updatedMarkers = updatedCoordinates.map((i: any) => {
      return {
        longitude: i[0],
        latitude: i[1],
      };
    });

    props.onUpdate(updatedMarkers).then(() => {
      setCoordinates(updatedCoordinates);
      updateLogs();
      setUpdatedCoordinates([]);
    });
  };

  useEffect(() => {
    if (mapRef.current && selectedLog) {
      setCoordinates(selectedLog.coordinates);
    }
  }, [selectedLog, mapRef]);

  let updateLogs = () => {
    getMapLogs(props.request._id).then((res) => {
      let _logs = new Array<CoordinatesLogs>();

      if (res) {
        for (let log of res) {
          if (log.data && log.data.markers) {
            let coords = log.data.markers.map((m: any) => {
              return [m.longitude, m.latitude];
            });

            let author = "";
            switch (log.role) {
              case "owner":
                author = "Administrador";
                break;
              case "auditor":
                author = "Oráculo";
                break;
              case "user":
                author = "Usuario";
                break;
            }

            let logDate = new Date(log.createdAt);
            let formattedLogDate = `${logDate.getDate()}/${logDate.getMonth() + 1}/${logDate.getFullYear()} ${logDate.getHours()}:${logDate.getMinutes()}`;

            _logs.push({
              id: log._id,
              title: [author, " - ", formattedLogDate].join(""),
              coordinates: coords,
            });
          }
        }
      }

      setLogs(_logs);
    });
  };

  useEffect(() => {
    if (!props.open) {
      setUpdatedCoordinates([]);
    } else {
      updateLogs();
    }
  }, [props.open]);

  let updateMap = () => {
    if (mapRef.current) {
      map.setTarget(mapRef.current);
      map.updateSize();

      if (updatedCoordinates.length > 0) {
        setCenter(updatedCoordinates[0]);
        drawRegion(updatedCoordinates);
      } else {
        setCenter(coordinates[0]);
        drawRegion(coordinates);
      }
    }
  };

  useEffect(() => {
    updateMap();
  }, [coordinates, updatedCoordinates]);

  useEffect(() => {
    if (mapRef.current) {
      map.setTarget(mapRef.current);
      map.updateSize();
    }
  }, [map]);

  const downloadKMLFile = () => {
    const text =
      '<?xml version="1.0" encoding="UTF-8"?>\n' +
      '<kml xmlns="http://www.opengis.net/kml/2.2">\n' +
      "  <Placemark>\n" +
      "    <name>Campo</name>\n" +
      "    <Polygon>\n" +
      "      <altitudeMode>relativeToGround</altitudeMode>\n" +
      "      <outerBoundaryIs>\n" +
      "        <LinearRing>\n" +
      "          <coordinates>\n" +
      ` ${props.request.markers
        .map((i) => {
          return `${i.longitude},${i.latitude},0`;
        })
        .join("\n")}` +
      "\n" +
      "          </coordinates>\n" +
      "        </LinearRing>\n" +
      "      </outerBoundaryIs>\n" +
      "    </Polygon>\n" +
      "  </Placemark>\n" +
      "</kml>";

    const blob = new Blob([text], {
      type: "application/vnd.google-earth.kml+xml",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = "coordinates.kml";
    link.href = url;
    link.click();
  };

  useEffect(() => {
    if (shapefile) {
      let data = shapefile.parse("shp");
      if (data.records[0]) {
        let record = data.records[0];
        if (record.body.type == ShapeType.Polygon) {
          let d: any = record.body.data;
          let _coordinates = d.points.map((i: any) => {
            return [i.x, i.y];
          });

          setUpdatedCoordinates(_coordinates);
        }
      }
    }
  }, [shapefile]);

  let loadOriginalMarkers = () => {
    var _coordinates: number[][] = [];
    if (props.request.markers) {
      _coordinates = props.request.markers.map((i) => {
        return [i.longitude, i.latitude];
      });
    }

    setSelectedLog(undefined);
    setCoordinates(_coordinates);
  };

  useEffect(() => {
    loadOriginalMarkers();
  }, [props.request]);

  useEffect(() => {
    function handleResize() {
      setTimeout(function () {
        // Update container size
        updateMap();
      }, 300);
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [props.open, map]);

  return (
    <Dialog open={props.open} fullWidth maxWidth="md">
      <DialogTitle>Mapa</DialogTitle>
      <DialogContent dividers>
        <Stack
          direction={"row"}
          useFlexGap
          spacing={2}
          style={{ paddingTop: 10 }}
        >
          <Select
            style={{ flexGrow: 1 }}
            defaultValue={"actual"}
            value={selectedLog?.id}
            onChange={(event: SelectChangeEvent) => {
              if (event.target.value != "actual") {
                setSelectedLog(logs.find((i) => i.id == event.target.value));
              } else {
                loadOriginalMarkers();
              }
            }}
          >
            <MenuItem value={"actual"}>Actual</MenuItem>;
            {logs.map((l) => {
              return <MenuItem value={l.id}>{l.title}</MenuItem>;
            })}
          </Select>
          <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
          >
            Cargar Actualización
            <VisuallyHiddenInput
              type="file"
              onChange={(e) => {
                if (e.target.files && e.target.files.length > 0) {
                  e.target.files[0].arrayBuffer().then((arrayBuffer) => {
                    // Load the .zip file to expose its contents
                    Shapefile.load(arrayBuffer).then((_shapefile) => {
                      // Set shapefile state
                      let key = Object.keys(_shapefile)[0];
                      if (key) {
                        setShapefile(_shapefile[key]);
                      }
                    });
                  });
                }
              }}
            />
          </Button>
        </Stack>

        <div
          className="map-container"
          style={{ width: "100%", height: "50vh", paddingTop: 10 }}
        >
          <div
            id="map"
            ref={mapRef}
            style={{ width: "100%", height: "100%" }}
          ></div>
        </div>
      </DialogContent>
      <DialogActions>
        {updatedCoordinates.length > 0 ? (
          <Button
            variant="contained"
            color="success"
            autoFocus
            onClick={handleUpdate}
          >
            Actualizar Coordenadas
          </Button>
        ) : null}
        <Button
          variant="contained"
          color="secondary"
          autoFocus
          onClick={downloadKMLFile}
        >
          Descargar KML
        </Button>
        <Button
          variant="contained"
          color="secondary"
          autoFocus
          onClick={props.onClose}
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
